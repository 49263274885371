import React, {useEffect, useState} from 'react';
import Plot from 'react-plotly.js';

const remote = process.env.REACT_APP_API || 'http://localhost:3030/';

function Distancemap({fetchURL, title}) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasError, setErrors] = useState(false);

    async function fetchData() {
        const res = await fetch(remote + fetchURL);
        res
            .json()
            .then(res => {
                setData(res);
                setLoading(false)
            })
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        setLoading(true);
        fetchData()
    }, [fetchURL]);

    return <Plot
        data={[
            {
                z: data ? data : [],
                type: 'heatmap',
                colorscale: 'YIOrRd',
                colorbar:{
                    title: "Distance in Å",
                    titleside:'right',
                },
            },
        ]}
        layout={
            {
                hovermode: false,
                width: 480,
                height: 480,
                title: loading ? "Loading...." : title + ' distance map',
                xaxis: {
                    autorange: 'reversed',
                    // autotick: false,
                    // fixedrange: true,
                    showticklabels: false,
                    // range: [0, 1000]
                },
                yaxis: {
                    // autotick: false,
                    showticklabels: false,
                    // fixedrange: true
                }
            }
        }
    />


}

export default Distancemap;
