import React, {useEffect, useState} from 'react';
import { Dropdown } from 'semantic-ui-react'
import NGL from "./NGL";

function Structure({structures}) {
    const [structure, setStructure] = useState(null);

    useEffect(() => {
        setStructure(null);
    }, [structures]);

    const stateOptions = Object.keys(structures).map((structureName, index) => ({
        key: structureName,
        text: structureName,
        value: structures[structureName],
    }));

    return (
        <div>
            Select a structure to display:
            <Dropdown placeholder='structure' search selection
                      options={stateOptions}
                      onChange={(e, { value }) => setStructure(value)}
            />
            {structure && <NGL fetchURL={structure} />}
        </div>
    )

}

export default Structure;
