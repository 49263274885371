import React, {useEffect, useState} from 'react';
import SequenceHighlighter from "./fasta/SequenceHighlighter";
import { fromFasta, alphabets } from 'protein-parser';

const remote = process.env.REACT_APP_API || 'http://localhost:3030/';

function Sequence({fetchURL}) {
    const [data, setData] = useState(null);
    const [header, setHeader] = useState(null);
    const [hasError, setErrors] = useState(false);

    async function fetchData() {
        const res = await fetch(remote + fetchURL);
        res
            .text()
            .then(res => {
                fromFasta(res, alphabets.IUPAC2)
                    .then(([proteins, headers]) => {
                        setData(proteins[0]);
                        setHeader(headers[0].header);
                    })
            })
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchData()
    }, [fetchURL]);

    if(!data){
        return <div></div>
    } else {
        return <div>
            <h2>{header}</h2>
            <SequenceHighlighter string={data.sequence}/>
        </div>
    }

}

export default Sequence;
