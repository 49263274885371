import React, { useState, useEffect } from 'react';
import Sequence from "../Sequence";
import Distancemap from '../Distancemap';
import Contactmap from "../Contactmap";
import Structure from "../Structure";
import './Protein.css';
import Errormap from "../Errormap";

const remote = process.env.REACT_APP_API || 'http://localhost:3030/';

function Protein({protein}) {
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({});

  let fetchURL = remote + "?p=" + protein;

  async function fetchData() {
    const res = await fetch(fetchURL);
    res
        .json()
        .then(res => setData(res))
        .catch(err => setErrors(err));
  }

  useEffect(() => {
    fetchData()
  }, [protein]);

  if(hasError){
    return <div>{hasError}</div>
  }

  return(
      <div className={"Protein"}>
        {data.fasta && data.alignment && data.distamaps && data.structures &&
        <div>
          <Sequence fetchURL={data.fasta}/>
          <br/>
          <p style={{fontSize: "1.5em"}}>
            <a href={"https://fast.alignmentviewer.org/?alignment-url=" + encodeURIComponent(remote + data.alignment)} target={"_blank"}> View alignment in alignmentviewer > </a>
          </p>
          {data.distamaps.mixed && <div>
            <h3>Mixed model</h3>
            <p>This model combines in-house built language models (such as <a href={"https://doi.org/10.1186/s12859-019-3220-8"} target={"_blank"}>SeqVec</a>) and an Evolutionary Couplings (EC) model.</p>
            <Distancemap fetchURL={data.distamaps.mixed} title={"mixed"}/>
            <Contactmap fetchURL={data.contamaps.mixed} title={"mixed"}/>
            <Errormap fetchURL={data.errormaps.mixed} title={"mixed"}/>
            <Structure structures={data.structures.mixed} />
          </div>}

          {data.distamaps.lm && <div>
            <h3>Language model</h3>
            <p>This model is based on <a href={"https://doi.org/10.1186/s12859-019-3220-8"} target={"_blank"}>SeqVec</a> and uses only sequence information (without alignments).</p>
            <Distancemap fetchURL={data.distamaps.lm} title={"LM"}/>
            <Contactmap fetchURL={data.contamaps.lm} title={"LM"}/>
            <Errormap fetchURL={data.errormaps.lm} title={"LM"}/>
            <Structure structures={data.structures.lm}/>
          </div>}

          {data.distamaps.ec && <div>
            <h3>EC model</h3>
            <p>This model is based on Evolutionary Couplings (ECs).</p>
            <Distancemap fetchURL={data.distamaps.ec} title={"EC"}/>
            <Contactmap fetchURL={data.contamaps.ec} title={"EC"}/>
            <Errormap fetchURL={data.errormaps.ec} title={"EC"}/>
            <Structure structures={data.structures.ec}/>
          </div>}
        </div>
        }
      </div>
  )
}

export default Protein;
