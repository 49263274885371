import React from 'react';
import {NGLContainer} from 'bioblocks-viz';

const remote = process.env.REACT_APP_API || 'http://localhost:3030/';

function NGL({fetchURL}) {

    let path = remote + fetchURL;

    return (
        <NGLContainer
            experimentalProteins={[]}
            predictedProteins={[path]}

        />
    )
}

export default NGL;
