import React, {useEffect, useState} from 'react';
import Protein from "./components/protein/Protein";
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import {Dropdown} from "semantic-ui-react";

const remote = process.env.REACT_APP_API || 'http://localhost:3030/';

function App() {
    const [hasError, setErrors] = useState(false);
    const [data, setData] = useState([]);
    const [protein, setProtein] = useState(null);

    async function fetchData() {
        const res = await fetch(remote);
        res
            .json()
            .then(res => {
                setData(res)
            })
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchData()
    }, [protein]);

    if(hasError){
        return <div>{hasError}</div>
    }

    const stateOptions = data.map((targetName, index) => ({
        key: targetName,
        text: targetName,
        value: targetName,
    }));

    return(
        <div className={"App"}>
            <h1>Rostlab CASP Covid prediction results</h1>
            <div>
                Select a CASP target to display data: &nbsp;
                <Dropdown placeholder='structure' search selection
                          options={stateOptions}
                          onChange={(e, { value }) => setProtein(value)}
                />
            </div>
            {protein && <Protein protein={protein}/>}
            <div>
                <h3>About</h3>
                <p>Structure prediction for the special CASP challenge (covid-19) by the <a target={"_blank"} href={"https://rostlab.org"}>Rostlab</a>.</p>
                <p>3D folds predicted using <a target={"_blank"} href={"https://doi.org/10.1073/pnas.1914677117"}>PyRosetta</a>.</p>
                <h4>People</h4>
                <h5>Contributors</h5>
                <ul>
                    <li>Konstantin Weißenow @ Rostlab</li>
                    <li>Daniel Berenberg @ Flatiron Institute</li>
                    <li>Michael Heinzinger @ Rostlab</li>
                    <li>Christian Dallago @ Rostlab</li>
                    <li>Maria Littmann @ Rostlab</li>
                    <li>Michael Bernhofer @ Rostlab</li>
                </ul>
                <h5>Support</h5>
                <ul>
                    <li>Andrea Schafferhans @ Rostlab</li>
                    <li>Jonas Reeb @ Rostlab</li>
                    <li>Lothar Richter @ Rostlab</li>
                </ul>
                <h5>Special thanks</h5>
                <ul>
                    <li>Reinhard Schneider @ UniLu</li>
                    <li>Valentin Plugaru @ UniLu</li>
                    <li>Ian Fisk @ Flatiron Institute</li>
                </ul>
                <h5>Administrative and technical</h5>
                <ul>
                    <li>Tim Karl @ Rostlab</li>
                    <li>Inga Weisse @ Rostlab</li>
                </ul>
                <h5>Supervision</h5>
                <ul>
                    <li>Burkhard Rost @ Rostlab</li>
                </ul>
            </div>
        </div>
    )

}

export default App;
